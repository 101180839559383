@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@300;400;500;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;400;500;700&display=swap');

/* Overpass */
/*
300 - Light
400 - Regular
500 - Medium
700 - Bold
800 - Black
*/

/*
IBM Plex Mono
300 - Light
400 - Regular
500 - Medium
700 - Bold
800 - Black (Not available)
*/

/* LOUIS */
@font-face {
  font-family: 'louiscondensed_demi';
  src: url('./louis/louis-condenseddemi-webfont.woff2') format('woff2'),
    url('./louis/louis-condenseddemi-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'louiscondensed_regular';
  src: url('./louis/louis-condensedregular-webfont.woff2') format('woff2'),
    url('./louis/louis-condensedregular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'louisregular';
  src: url('./louis/louis-regular-webfont.woff2') format('woff2'),
    url('./louis/louis-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'louisbold';
  src: url('./louis/louis-bold-webfont.woff2') format('woff2'),
    url('./louis/louis-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'louisheavy';
  src: url('./louis/louis-heavy-webfont.woff2') format('woff2'),
    url('./louis/louis-heavy-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'louisitalic';
  src: url('./louis/louis-italic-webfont.woff2') format('woff2'),
    url('./louis/louis-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'louisheavy_italic';
  src: url('./louis/louis-heavyitalic-webfont.woff2') format('woff2'),
    url('./louis/louis-heavyitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'louisbold_italic';
  src: url('./louis-global/louisglobal2-bolditalic-webfont.woff2') format('woff2'),
    url('./louis-global/louisglobal2-bolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* LOUIS GLOBAL */
@font-face {
  font-family: 'louis_global_2_heavyregular';
  src: url('./louis-global/louisglobal2-heavy-webfont.woff2') format('woff2'),
    url('./louis-global/louisglobal2-heavy-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'louis_global_2_boldregular';
  src: url('./louis-global/louisglobal2-bold-webfont.woff2') format('woff2'),
    url('./louis-global/louisglobal2-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'louis_global_2_condensed_reRg';
  src: url('./louis-global/louisglobal2-condensedreg-webfont.woff2') format('woff2'),
    url('./louis-global/louisglobal2-condensedreg-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'louis_global_2_heavy_italicRg';
  src: url('./louis-global/louisglobal2-heavyitalic-webfont.woff2') format('woff2'),
    url('./louis-global/louisglobal2-heavyitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'louis_global_2_regregular';
  src: url('./louis-global/louisglobal2-reg-webfont.woff2') format('woff2'),
    url('./louis-global/louisglobal2-reg-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'louis_global_2_condensed_deRg';
  src: url('./louis-global/louisglobal2-condenseddemi-webfont.woff2') format('woff2'),
    url('./louis-global/louisglobal2-condenseddemi-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'louis_global_2_bold_italicRg';
  src: url('./louis-global/louisglobal2-bolditalic-webfont.woff2') format('woff2'),
    url('./louis-global/louisglobal2-bolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'louis_global_2_italicregular';
  src: url('./louis-global/louisglobal2-italic-webfont.woff2') format('woff2'),
    url('./louis-global/louisglobal2-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Cadillac US */
/* Cadillac Gothic */
/* @font-face {
  font-family: 'CadillacGothic-Regular';
  src: url('./cadillac-gothic/CadillacGothic-Regular.ttf') format('truetype');
  src: url('./cadillac-gothic/CadillacGothic-Regular.woff') format('woff'),
    url('./cadillac-gothic/CadillacGothic-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CadillacGothic-Medium';
  src: url('./cadillac-gothic/CadillacGothic-Medium.ttf') format('truetype');
  src: url('./cadillac-gothic/CadillacGothic-Medium.woff') format('woff'),
    url('./cadillac-gothic/CadillacGothic-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CadillacGothic-Bold';
  src: url('./cadillac-gothic/CadillacGothic-Bold.ttf') format('truetype');
  src: url('./cadillac-gothic/CadillacGothic-Bold.woff') format('woff'),
    url('./cadillac-gothic/CadillacGothic-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CadillacGothic-WideRegular';
  src: url('./cadillac-gothic/CadillacGothic-WideRegular.ttf') format('truetype');
  src: url('./cadillac-gothic/CadillacGothic-WideRegular.woff') format('woff'),
  url('./cadillac-gothic/CadillacGothic-WideRegular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CadillacGothic-WideMedium';
  src: url('./cadillac-gothic/CadillacGothic-WideMedium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CadillacGothic-WideBold';
  src: url('./cadillac-gothic/CadillacGothic-WideBold.ttf') format('truetype');
  src: url('./cadillac-gothic/CadillacGothic-WideBold.woff') format('woff'),
  url('./cadillac-gothic/CadillacGothic-WideBold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
} */
/* END Cadillac US */

/* Cadillac Canada */
/* Cadillac Sans */
/* @font-face {
  font-family: 'CadillacSansA-Thin';
  src: url('./cadillac-sans/CadillacSansA-Thin.eot');
  src: url('./cadillac-sans/CadillacSansA-Thin.eot?#iefix') format('embedded-opentype'),
      url('./cadillac-sans/CadillacSansA-Thin.woff2') format('woff2'),
      url('./cadillac-sans/CadillacSansA-Thin.woff') format('woff'),
      url('./cadillac-sans/CadillacSansA-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CadillacSansA-ThinItalic';
  src: url('./cadillac-sans/CadillacSansA-ThinItalic.eot');
  src: url('./cadillac-sans/CadillacSansA-ThinItalic.eot?#iefix') format('embedded-opentype'),
      url('./cadillac-sans/CadillacSansA-ThinItalic.woff2') format('woff2'),
      url('./cadillac-sans/CadillacSansA-ThinItalic.woff') format('woff'),
      url('./cadillac-sans/CadillacSansA-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'CadillacSansA-Light';
  src: url('./cadillac-sans/CadillacSansA-Light.eot');
  src: url('./cadillac-sans/CadillacSansA-Light.eot?#iefix') format('embedded-opentype'),
      url('./cadillac-sans/CadillacSansA-Light.woff2') format('woff2'),
      url('./cadillac-sans/CadillacSansA-Light.woff') format('woff'),
      url('./cadillac-sans/CadillacSansA-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CadillacSansA-LightItalic';
  src: url('./cadillac-sans/CadillacSansA-LightItalic.eot');
  src: url('./cadillac-sans/CadillacSansA-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('./cadillac-sans/CadillacSansA-LightItalic.woff2') format('woff2'),
      url('./cadillac-sans/CadillacSansA-LightItalic.woff') format('woff'),
      url('./cadillac-sans/CadillacSansA-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'CadillacSansA-Book';
  src: url('./cadillac-sans/CadillacSansA-Book.eot');
  src: url('./cadillac-sans/CadillacSansA-Book.eot?#iefix') format('embedded-opentype'),
      url('./cadillac-sans/CadillacSansA-Book.woff2') format('woff2'),
      url('./cadillac-sans/CadillacSansA-Book.woff') format('woff'),
      url('./cadillac-sans/CadillacSansA-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CadillacSansA-BookItalic';
  src: url('./cadillac-sans/CadillacSansA-BookItalic.eot');
  src: url('./cadillac-sans/CadillacSansA-BookItalic.eot?#iefix') format('embedded-opentype'),
      url('./cadillac-sans/CadillacSansA-BookItalic.woff2') format('woff2'),
      url('./cadillac-sans/CadillacSansA-BookItalic.woff') format('woff'),
      url('./cadillac-sans/CadillacSansA-BookItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'CadillacSansA-Medium';
  src: url('./cadillac-sans/CadillacSansA-Medium.eot');
  src: url('./cadillac-sans/CadillacSansA-Medium.eot?#iefix') format('embedded-opentype'),
      url('./cadillac-sans/CadillacSansA-Medium.woff2') format('woff2'),
      url('./cadillac-sans/CadillacSansA-Medium.woff') format('woff'),
      url('./cadillac-sans/CadillacSansA-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CadillacSansA-MediumItalic';
  src: url('./cadillac-sans/CadillacSansA-MediumItalic.eot');
  src: url('./cadillac-sans/CadillacSansA-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('./cadillac-sans/CadillacSansA-MediumItalic.woff2') format('woff2'),
      url('./cadillac-sans/CadillacSansA-MediumItalic.woff') format('woff'),
      url('./cadillac-sans/CadillacSansA-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'CadillacSansA-SemiBold';
  src: url('./cadillac-sans/CadillacSansA-SemiBold.eot');
  src: url('./cadillac-sans/CadillacSansA-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('./cadillac-sans/CadillacSansA-SemiBold.woff2') format('woff2'),
      url('./cadillac-sans/CadillacSansA-SemiBold.woff') format('woff'),
      url('./cadillac-sans/CadillacSansA-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CadillacSansA-SemiBoldItalic';
  src: url('./cadillac-sans/CadillacSansA-SemiBoldItalic.eot');
  src: url('./cadillac-sans/CadillacSansA-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('./cadillac-sans/CadillacSansA-SemiBoldItalic.woff2') format('woff2'),
      url('./cadillac-sans/CadillacSansA-SemiBoldItalic.woff') format('woff'),
      url('./cadillac-sans/CadillacSansA-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'CadillacSansA-Bold';
  src: url('./cadillac-sans/CadillacSansA-Bold.eot');
  src: url('./cadillac-sans/CadillacSansA-Bold.eot?#iefix') format('embedded-opentype'),
      url('./cadillac-sans/CadillacSansA-Bold.woff2') format('woff2'),
      url('./cadillac-sans/CadillacSansA-Bold.woff') format('woff'),
      url('./cadillac-sans/CadillacSansA-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CadillacSansA-BoldItalic';
  src: url('./cadillac-sans/CadillacSansA-BoldItalic.eot');
  src: url('./cadillac-sans/CadillacSansA-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('./cadillac-sans/CadillacSansA-BoldItalic.woff2') format('woff2'),
      url('./cadillac-sans/CadillacSansA-BoldItalic.woff') format('woff'),
      url('./cadillac-sans/CadillacSansA-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'CadillacSansA-ExtraBold';
  src: url('./cadillac-sans/CadillacSansA-ExtraBold.eot');
  src: url('./cadillac-sans/CadillacSansA-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('./cadillac-sans/CadillacSansA-ExtraBold.woff2') format('woff2'),
      url('./cadillac-sans/CadillacSansA-ExtraBold.woff') format('woff'),
      url('./cadillac-sans/CadillacSansA-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CadillacSansA-ExtraBoldItalic';
  src: url('./cadillac-sans/CadillacSansA-ExtraBoldItalic.eot');
  src: url('./cadillac-sans/CadillacSansA-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('./cadillac-sans/CadillacSansA-ExtraBoldItalic.woff2') format('woff2'),
      url('./cadillac-sans/CadillacSansA-ExtraBoldItalic.woff') format('woff'),
      url('./cadillac-sans/CadillacSansA-ExtraBoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
} */

/* Cadillac Serif */
/* @font-face {
  font-family: 'CadillacSerif';
  src: url('./cadillac-serif/CadillacSerif.eot');
  src: url('./cadillac-serif/CadillacSerif.eot?#iefix') format('embedded-opentype'),
      url('./cadillac-serif/CadillacSerif.woff2') format('woff2'),
      url('./cadillac-serif/CadillacSerif.woff') format('woff'),
      url('./cadillac-serif/CadillacSerif.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CadillacSerif-Italic';
  src: url('./cadillac-serif/CadillacSerif-Italic.eot');
  src: url('./cadillac-serif/CadillacSerif-Italic.eot?#iefix') format('embedded-opentype'),
      url('./cadillac-serif/CadillacSerif-Italic.woff2') format('woff2'),
      url('./cadillac-serif/CadillacSerif-Italic.woff') format('woff'),
      url('./cadillac-serif/CadillacSerif-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'CadillacSerif-Medium';
  src: url('./cadillac-serif/CadillacSerif-Medium.eot');
  src: url('./cadillac-serif/CadillacSerif-Medium.eot?#iefix') format('embedded-opentype'),
      url('./cadillac-serif/CadillacSerif-Medium.woff2') format('woff2'),
      url('./cadillac-serif/CadillacSerif-Medium.woff') format('woff'),
      url('./cadillac-serif/CadillacSerif-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CadillacSerif-MediumItalic';
  src: url('./cadillac-serif/CadillacSerif-MediumItalic.eot');
  src: url('./cadillac-serif/CadillacSerif-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('./cadillac-serif/CadillacSerif-MediumItalic.woff2') format('woff2'),
      url('./cadillac-serif/CadillacSerif-MediumItalic.woff') format('woff'),
      url('./cadillac-serif/CadillacSerif-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'CadillacSerif-SemiBold';
  src: url('./cadillac-serif/CadillacSerif-SemiBold.eot');
  src: url('./cadillac-serif/CadillacSerif-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('./cadillac-serif/CadillacSerif-SemiBold.woff2') format('woff2'),
      url('./cadillac-serif/CadillacSerif-SemiBold.woff') format('woff'),
      url('./cadillac-serif/CadillacSerif-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CadillacSerif-SemiBoldItalic';
  src: url('./cadillac-serif/CadillacSerif-SemiBoldItalic.eot');
  src: url('./cadillac-serif/CadillacSerif-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('./cadillac-serif/CadillacSerif-SemiBoldItalic.woff2') format('woff2'),
      url('./cadillac-serif/CadillacSerif-SemiBoldItalic.woff') format('woff'),
      url('./cadillac-serif/CadillacSerif-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'CadillacSerif-Bold';
  src: url('./cadillac-serif/CadillacSerif-Bold.eot');
  src: url('./cadillac-serif/CadillacSerif-Bold.eot?#iefix') format('embedded-opentype'),
      url('./cadillac-serif/CadillacSerif-Bold.woff2') format('woff2'),
      url('./cadillac-serif/CadillacSerif-Bold.woff') format('woff'),
      url('./cadillac-serif/CadillacSerif-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CadillacSerif-BoldItalic';
  src: url('./cadillac-serif/CadillacSerif-BoldItalic.eot');
  src: url('./cadillac-serif/CadillacSerif-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('./cadillac-serif/CadillacSerif-BoldItalic.woff2') format('woff2'),
      url('./cadillac-serif/CadillacSerif-BoldItalic.woff') format('woff'),
      url('./cadillac-serif/CadillacSerif-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'CadillacSerif-Heavy';
  src: url('./cadillac-serif/CadillacSerif-Heavy.eot');
  src: url('./cadillac-serif/CadillacSerif-Heavy.eot?#iefix') format('embedded-opentype'),
      url('./cadillac-serif/CadillacSerif-Heavy.woff2') format('woff2'),
      url('./cadillac-serif/CadillacSerif-Heavy.woff') format('woff'),
      url('./cadillac-serif/CadillacSerif-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CadillacSerif-HeavyItalic';
  src: url('./cadillac-serif/CadillacSerif-HeavyItalic.eot');
  src: url('./cadillac-serif/CadillacSerif-HeavyItalic.eot?#iefix') format('embedded-opentype'),
      url('./cadillac-serif/CadillacSerif-HeavyItalic.woff2') format('woff2'),
      url('./cadillac-serif/CadillacSerif-HeavyItalic.woff') format('woff'),
      url('./cadillac-serif/CadillacSerif-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
} */
/* END Cadillac Canada */

/* GMC Fonts */

/* Muli */
/* @font-face {
  font-family: 'muliitalic';
  src: url('./muli/muli-italic-webfont.woff2') format('woff2'),
    url('./muli/muli-italic-webfont.woff') format('woff'),
    url('./muli/Muli-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'mulilight';
  src: url('./muli/muli-light-webfont.woff2') format('woff2'),
    url('./muli/muli-light-webfont.woff') format('woff'),
    url('./muli/Muli-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'mulilight_italic';
  src: url('./muli/muli-lightitalic-webfont.woff2') format('woff2'),
    url('./muli/muli-lightitalic-webfont.woff') format('woff'),
    url('./muli/Muli-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'muliextralight';
  src: url('./muli/muli-extralight-webfont.woff2') format('woff2'),
    url('./muli/muli-extralight-webfont.woff') format('woff'),
    url('./muli/Muli-ExtraLight.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'muliextralight_italic';
  src: url('./muli/muli-extralightitalic-webfont.woff2') format('woff2'),
    url('./muli/muli-extralightitalic-webfont.woff') format('woff'),
    url('./muli/Muli-ExtraLightItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'muliregular';
  src: url('./muli/muli-regular-webfont.woff2') format('woff2'),
    url('./muli/muli-regular-webfont.woff') format('woff'),
    url('./muli/Muli-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'mulisemibold';
  src: url('./muli/muli-semibold-webfont.woff2') format('woff2'),
    url('./muli/muli-semibold-webfont.woff') format('woff'),
    url('./muli/Muli-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'mulisemibold_italic';
  src: url('./muli/muli-semibolditalic-webfont.woff2') format('woff2'),
    url('./muli/muli-semibolditalic-webfont.woff') format('woff'),
    url('./muli/Muli-SemiBoldItalic.ttf') format('truetype');
  font-weight: normal;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'mulibold';
  src: url('./muli/muli-bold-webfont.woff2') format('woff2'),
  url('./muli/muli-bold-webfont.woff') format('woff'),
    url('./muli/Muli-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'mulibold_italic';
  src: url('./muli/muli-bolditalic-webfont.woff2') format('woff2'),
    url('./muli/muli-bolditalic-webfont.woff') format('woff'),
    url('./muli/Muli-BoldItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'muliextrabold';
  src: url('./muli/muli-extrabold-webfont.woff2') format('woff2'),
    url('./muli/muli-extrabold-webfont.woff') format('woff'),
    url('./muli/Muli-ExtraBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'muliextrabold_italic';
  src: url('./muli/muli-extrabolditalic-webfont.woff2') format('woff2'),
    url('./muli/muli-extrabolditalic-webfont.woff') format('woff'),
    url('./muli/Muli-ExtraBoldItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'muliblack';
  src: url('./muli/muli-black-webfont.woff2') format('woff2'),
    url('./muli/muli-black-webfont.woff') format('woff'),
    url('./muli/Muli-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'muliblack_italic';
  src: url('./muli/muli-blackitalic-webfont.woff2') format('woff2'),
    url('./muli/muli-blackitalic-webfont.woff') format('woff'),
    url('./muli/Muli-BlackItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
} */

/* StratumGMC */
/* @font-face {
  font-family: 'StratumGMC-Thin';
  src: url('./stratumGMC/StratumGMC-Thin.woff2') format('woff2'),
    url('./stratumGMC/StratumGMC-Thin.woff') format('woff'),
    url('./stratumGMC/StratumGMC-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'StratumGMC-Light';
  src: url('./stratumGMC/StratumGMC-Light.woff2') format('woff2'),
   url('./stratumGMC/StratumGMC-Light.woff') format('woff'),
   url('./stratumGMC/StratumGMC-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'StratumGMC-Regular';
  src: url('./stratumGMC/StratumGMC-Regular.woff2') format('woff2'),
    url('./stratumGMC/StratumGMC-Regular.woff') format('woff'),
    url('./stratumGMC/StratumGMC-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'StratumGMC-Medium';
  src: url('./stratumGMC/StratumGMC-Medium.woff2') format('woff2'),
    url('./stratumGMC/StratumGMC-Medium.woff') format('woff'),
    url('./stratumGMC/StratumGMC-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'StratumGMC-Bold';
  src: url('./stratumGMC/StratumGMC-Bold.woff2') format('woff2'),
    url('./stratumGMC/StratumGMC-Bold.woff') format('woff'),
    url('./stratumGMC/StratumGMC-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'StratumGMC-Black';
  src: url('./stratumGMC/StratumGMC-Black.woff2') format('woff2'),
    url('./stratumGMC/StratumGMC-Black.woff') format('woff'),
    url('./stratumGMC/StratumGMC-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
} */

/* BuickFuturaND */
/* @font-face {
  font-family: 'BuickFuturaND-Light';
  src: url('./BuickFuturaND/BuickFuturaND-Light.woff2') format('woff2'),
      url('./BuickFuturaND/BuickFuturaND-Light.woff') format('woff'),
      url('./BuickFuturaND/BuickFuturaND-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BuickFuturaND-LightOblique';
  src: url('./BuickFuturaND/BuickFuturaND-LightOblique.woff2') format('woff2'),
      url('./BuickFuturaND/BuickFuturaND-LightOblique.woff') format('woff'),
      url('./BuickFuturaND/BuickFuturaND-LightOblique.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'BuickFuturaND-Book';
  src: url('./BuickFuturaND/BuickFuturaND-Book.woff2') format('woff2'),
      url('./BuickFuturaND/BuickFuturaND-Book.woff') format('woff'),
      url('./BuickFuturaND/BuickFuturaND-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BuickFuturaND-BookOblique';
  src: url('./BuickFuturaND/BuickFuturaND-BookOblique.woff2') format('woff2'),
      url('./BuickFuturaND/BuickFuturaND-BookOblique.woff') format('woff'),
      url('./BuickFuturaND/BuickFuturaND-BookOblique.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'BuickFuturaND-Medium';
  src: url('./BuickFuturaND/BuickFuturaND-Medium.woff2') format('woff2'),
      url('./BuickFuturaND/BuickFuturaND-Medium.woff') format('woff'),
      url('./BuickFuturaND/BuickFuturaND-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BuickFuturaND-MediumOblique';
  src: url('./BuickFuturaND/BuickFuturaND-MediumOblique.woff2') format('woff2'),
      url('./BuickFuturaND/BuickFuturaND-MediumOblique.woff') format('woff'),
      url('./BuickFuturaND/BuickFuturaND-MediumOblique.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
} */

/* AvenirNext */
/* @font-face {
  font-family: 'AvenirNext-UltraLight';
  src: url('./AvenirNext/AvenirNext-UltraLight.woff2') format('woff2'),
      url('./AvenirNext/AvenirNext-UltraLight.woff') format('woff'),
      url('./AvenirNext/AvenirNext-UltraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext-UltraLightItalic';
  src: url('./AvenirNext/AvenirNext-UltraLightItalic.woff2') format('woff2'),
      url('./AvenirNext/AvenirNext-UltraLightItalic.woff') format('woff'),
      url('./AvenirNext/AvenirNext-UltraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext-Regular';
  src: url('./AvenirNext/AvenirNext-Regular.woff2') format('woff2'),
      url('./AvenirNext/AvenirNext-Regular.woff') format('woff'),
      url('./AvenirNext/AvenirNext-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext-Italic';
  src: url('./AvenirNext/AvenirNext-Italic.woff2') format('woff2'),
      url('./AvenirNext/AvenirNext-Italic.woff') format('woff'),
      url('./AvenirNext/AvenirNext-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext-Medium';
  src: url('./AvenirNext/AvenirNext-Medium.woff2') format('woff2'),
      url('./AvenirNext/AvenirNext-Medium.woff') format('woff'),
      url('./AvenirNext/AvenirNext-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext-MediumItalic';
  src: url('./AvenirNext/AvenirNext-MediumItalic.woff2') format('woff2'),
      url('./AvenirNext/AvenirNext-MediumItalic.woff') format('woff'),
      url('./AvenirNext/AvenirNext-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext-DemiBold';
  src: url('./AvenirNext/AvenirNext-DemiBold.woff2') format('woff2'),
      url('./AvenirNext/AvenirNext-DemiBold.woff') format('woff'),
      url('./AvenirNext/AvenirNext-DemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext-DemiBoldItalic';
  src: url('./AvenirNext/AvenirNext-DemiBoldItalic.woff2') format('woff2'),
      url('./AvenirNext/AvenirNext-DemiBoldItalic.woff') format('woff'),
      url('./AvenirNext/AvenirNext-DemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext-Bold';
  src: url('./AvenirNext/AvenirNext-Bold.woff2') format('woff2'),
      url('./AvenirNext/AvenirNext-Bold.woff') format('woff'),
      url('./AvenirNext/AvenirNext-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext-BoldItalic';
  src: url('./AvenirNext/AvenirNext-BoldItalic.woff2') format('woff2'),
      url('./AvenirNext/AvenirNext-BoldItalic.woff') format('woff'),
      url('./AvenirNext/AvenirNext-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext-Heavy';
  src: url('./AvenirNext/AvenirNext-Heavy.woff2') format('woff2'),
      url('./AvenirNext/AvenirNext-Heavy.woff') format('woff'),
      url('./AvenirNext/AvenirNext-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext-HeavyItalic';
  src: url('./AvenirNext/AvenirNext-HeavyItalic.woff2') format('woff2'),
      url('./AvenirNext/AvenirNext-HeavyItalic.woff') format('woff'),
      url('./AvenirNext/AvenirNext-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
} */

/* Futura */
/* @font-face {
  font-family: 'Futura-Medium';
  src: url('./futura/Futura-Medium.woff2') format('woff2'),
      url('./futura/Futura-Medium.woff') format('woff'),
      url('./futura/Futura-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura-MediumItalic';
  src: url('./futura/Futura-MediumItalic.woff2') format('woff2'),
      url('./futura/Futura-MediumItalic.woff') format('woff'),
      url('./futura/Futura-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Futura-Bold';
  src: url('./futura/Futura-Bold.woff2') format('woff2'),
      url('./futura/Futura-Bold.woff') format('woff'),
      url('./futura/Futura-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura-CondensedMedium';
  src: url('./futura/Futura-CondensedMedium.woff2') format('woff2'),
      url('./futura/Futura-CondensedMedium.woff') format('woff'),
      url('./futura/Futura-CondensedMedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura-CondensedExtraBold';
  src: url('./futura/Futura-CondensedExtraBold.woff2') format('woff2'),
      url('./futura/Futura-CondensedExtraBold.woff') format('woff'),
      url('./futura/Futura-CondensedExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
} */

/* GM Energy */
/* Founders Grotesk X-Condensed (Test fonts, need to buy to license) */
@font-face {
  font-family: 'TestFoundersGroteskX-Condensed-Light';
  src: url('./TestFoundersGroteskX-Condensed/test-founders-grotesk-x-condensed-light.woff2') format('woff2'),
      url('./TestFoundersGroteskX-Condensed/TestFoundersGroteskX-Condensed-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TestFoundersGroteskX-Condensed-Regular';
  src: url('./TestFoundersGroteskX-Condensed/test-founders-grotesk-x-condensed-regular.woff2') format('woff2'),
      url('./TestFoundersGroteskX-Condensed/TestFoundersGroteskX-Condensed-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TestFoundersGroteskX-Condensed-Medium';
  src: url('./TestFoundersGroteskX-Condensed/test-founders-grotesk-x-condensed-medium.woff2') format('woff2'),
      url('./TestFoundersGroteskX-Condensed/TestFoundersGroteskX-Condensed-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TestFoundersGroteskX-Condensed-Semibold';
  src: url('./TestFoundersGroteskX-Condensed/test-founders-grotesk-x-condensed-semibold.woff2') format('woff2'),
      url('./TestFoundersGroteskX-Condensed/TestFoundersGroteskX-Condensed-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TestFoundersGroteskX-Condensed-Bold';
  src: url('./TestFoundersGroteskX-Condensed/test-founders-grotesk-x-condensed-bold.woff2') format('woff2'),
      url('./TestFoundersGroteskX-Condensed/TestFoundersGroteskX-Condensed-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* Founders Grotesk (Test fonts, need to buy to license) */
@font-face {
  font-family: 'TestFoundersGrotesk-Light';
  src: url('./TestFoundersGrotesk/test-founders-grotesk-light.woff2') format('woff2'),
      url('./TestFoundersGrotesk/TestFoundersGrotesk-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TestFoundersGrotesk-LightItalic';
  src: url('./TestFoundersGrotesk/test-founders-grotesk-light-italic.woff2') format('woff2'),
      url('./TestFoundersGrotesk/TestFoundersGrotesk-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TestFoundersGrotesk-Regular';
  src: url('./TestFoundersGrotesk/test-founders-grotesk-regular.woff2') format('woff2'),
      url('./TestFoundersGrotesk/TestFoundersGrotesk-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TestFoundersGrotesk-RegularItalic';
  src: url('./TestFoundersGrotesk/test-founders-grotesk-regular-italic.woff2') format('woff2'),
      url('./TestFoundersGrotesk/TestFoundersGrotesk-RegularItalic.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TestFoundersGrotesk-Medium';
  src: url('./TestFoundersGrotesk/test-founders-grotesk-medium.woff2') format('woff2'),
      url('./TestFoundersGrotesk/TestFoundersGrotesk-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TestFoundersGrotesk-MediumItalic';
  src: url('./TestFoundersGrotesk/test-founders-grotesk-medium-italic.woff2') format('woff2'),
      url('./TestFoundersGrotesk/TestFoundersGrotesk-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TestFoundersGrotesk-Semibold';
  src: url('./TestFoundersGrotesk/test-founders-grotesk-semibold.woff2') format('woff2'),
      url('./TestFoundersGrotesk/TestFoundersGrotesk-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TestFoundersGrotesk-SemiboldItalic';
  src: url('./TestFoundersGrotesk/test-founders-grotesk-semibold-italic.woff2') format('woff2'),
      url('./TestFoundersGrotesk/TestFoundersGrotesk-SemiboldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TestFoundersGrotesk-Bold';
  src: url('./TestFoundersGrotesk/test-founders-grotesk-bold.woff2') format('woff2'),
      url('./TestFoundersGrotesk/TestFoundersGrotesk-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TestFoundersGrotesk-BoldItalic';
  src: url('./TestFoundersGrotesk/test-founders-grotesk-bold-italic.woff2') format('woff2'),
      url('./TestFoundersGrotesk/TestFoundersGrotesk-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FoundersGroteskCondensed-Light';
  src: url('./FoundersGroteskCondensed/founders-grotesk-condensed-light.woff2') format('woff2'),
      url('./FoundersGroteskCondensed/FoundersGroteskCondensed-Light.ttf') format('truetype'),
      url('./FoundersGroteskCondensed/FoundersGroteskCondensed-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FoundersGroteskCondensed-Regular';
  src: url('./FoundersGroteskCondensed/founders-grotesk-condensed-regular.woff2') format('woff2'),
      url('./FoundersGroteskCondensed/FoundersGroteskCondensed-Regular.ttf') format('truetype'),
      url('./FoundersGroteskCondensed/FoundersGroteskCondensed-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FoundersGroteskCondensed-Medium';
  src: url('./FoundersGroteskCondensed/founders-grotesk-condensed-medium.woff2') format('woff2'),
      url('./FoundersGroteskCondensed/FoundersGroteskCondensed-Medium.ttf') format('truetype'),
      url('./FoundersGroteskCondensed/FoundersGroteskCondensed-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FoundersGroteskCondensed-Semibold';
  src: url('./FoundersGroteskCondensed/founders-grotesk-condensed-semibold.woff2') format('woff2'),
      url('./FoundersGroteskCondensed/FoundersGroteskCondensed-Semibold.ttf') format('truetype'),
      url('./FoundersGroteskCondensed/FoundersGroteskCondensed-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FoundersGroteskCondensed-Bold';
  src: url('./FoundersGroteskCondensed/founders-grotesk-condensed-bold.woff2') format('woff2'),
      url('./FoundersGroteskCondensed/FoundersGroteskCondensed-Bold.ttf') format('truetype'),
      url('./FoundersGroteskCondensed/FoundersGroteskCondensed-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
